i {
  font-size: 4rem;
}

#skills {
  color: white;
  height: 100vh;
  background: linear-gradient(45deg, #7F1720 30%, #202439 70%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-height: 442px) {
  #skills {
    height: 150vh;
  }
}
@media only screen and (max-height: 322px) {
  #skills {
    height: 250vh;
  }
}
@media only screen and (max-width: 960px) {
  #skills {
    height: 110vh;
  }
}
@media only screen and (max-width: 958px) {
  #skills {
    height: 140vh;
  }
}
@media only screen and (max-width: 630px) {
  #skills {
    height: 150vh;
  }
}
@media only screen and (max-width: 375px) {
  #skills {
    height: 180vh;
    padding: 1em;
  }
}
/* Galaxy Fold */
@media only screen and (max-width: 281px) {
  #skills {
    height: 230vh;
  }
}