#contact {
  color: white;
  height: 100vh;
  background: linear-gradient(45deg, #7F1720 30%, #202439 70%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.the-form {
  font-family: 'Futura';
  color: white;
}

@media only screen and (max-height: 960px) {
  #contact {
    height: 150vh;
  }
}
@media only screen and (max-height: 442px) {
  #contact {
    height: 170vh;
  }
}
@media only screen and (max-height: 394px) {
  #contact {
    height: 200vh;
  }
}
@media only screen and (max-height: 322px) {
  #contact {
    height: 250vh;
  }
}
@media only screen and (max-width: 960px) {
  #contact {
    height: 130vh;
  }
}
@media only screen and (max-width: 375px) {
  #contact {
    height: 125vh;
    padding: 1em;
  }
}