body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Harlow";
  src: local("HarlowRegular"),
   url("/static/media/Harlow Regular.c83625dd.ttf") format("truetype");
  font-weight: normal;
  }
@font-face {
  font-family: "HarlowSolid";
  src: local("HarlowEF-Solid"),
   url("/static/media/HarlowEF-Solid Regular.afaa6a2a.otf") format("opentype");
  font-weight: normal;
  }
@font-face {
  font-family: "Futura";
  src: local("futura"),
   url(/static/media/futur.9a162627.ttf) format("truetype");
  font-weight: normal;
  }
  

.App {
  text-align: center;
  margin: 0 auto;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

hr {
  height: 1px;
  background-color: white;
  border: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

i {
  font-size: 4rem;
}

#skills {
  color: white;
  height: 100vh;
  background: linear-gradient(45deg, #7F1720 30%, #202439 70%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-height: 442px) {
  #skills {
    height: 150vh;
  }
}
@media only screen and (max-height: 322px) {
  #skills {
    height: 250vh;
  }
}
@media only screen and (max-width: 960px) {
  #skills {
    height: 110vh;
  }
}
@media only screen and (max-width: 958px) {
  #skills {
    height: 140vh;
  }
}
@media only screen and (max-width: 630px) {
  #skills {
    height: 150vh;
  }
}
@media only screen and (max-width: 375px) {
  #skills {
    height: 180vh;
    padding: 1em;
  }
}
/* Galaxy Fold */
@media only screen and (max-width: 281px) {
  #skills {
    height: 230vh;
  }
}
#about {
  color: white;
  height: 100vh;
  background: linear-gradient(45deg, #202434 30%, #202440 70%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* @media only screen and (max-height: 960px) {
  #about {
    height: 130vh;
  }
}
@media only screen and (max-height: 394px) {
  #about {
    height: 200vh;
  }
}
@media only screen and (max-width: 960px) {
  #about {
    height: 130vh;
  }
}

@media only screen and (max-width: 375px) {
  #about {
    height: 140vh;
  }
} */
/* Galaxy Fold */
/* @media only screen and (max-width: 281px) {
  #about {
    height: 190vh;
  }
} */
#projects {
  color: white;
  max-height: 100vh;
  background: linear-gradient(45deg, #202434 30%, #202440 70%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

/* Nest Hub Max */
@media only screen and (max-height: 1281px) {
  #projects {
    height: 120vh;
  }
}
@media only screen and (max-height: 700px) {
  #projects {
    height: 150vh;
  }
}
@media only screen and (max-height: 322px) {
  #projects {
    height: 250vh;
  }
}
@media only screen and (max-width: 960px) {
  #projects {
    height: 130vh;
  }
}
@media only screen and (max-width: 958px) {
  #projects {
    height: 280vh;
  }
}

@media only screen and (max-width: 541px) {
  #projects {
    height: 310vh;
  }
}
/* iPhone XR */
@media only screen and (max-width: 415px) {
  #projects {
    height: 270vh;
  }
}
/* iPhone 12 Pro */
@media only screen and (max-width: 415px) {
  #projects {
    height: 290vh;
  }
}
/* iPhone SE */
@media only screen and (max-width: 375px) {
  #projects {
    height: 360vh;
  }
}
/* Galaxy S8+ */
@media only screen and (max-width: 361px) {
  #projects {
    height: 340vh;
  }
}
/* Galaxy Fold */
@media only screen and (max-width: 281px) {
  #projects {
    height: 400vh;
  }
}
#contact {
  color: white;
  height: 100vh;
  background: linear-gradient(45deg, #7F1720 30%, #202439 70%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.the-form {
  font-family: 'Futura';
  color: white;
}

@media only screen and (max-height: 960px) {
  #contact {
    height: 150vh;
  }
}
@media only screen and (max-height: 442px) {
  #contact {
    height: 170vh;
  }
}
@media only screen and (max-height: 394px) {
  #contact {
    height: 200vh;
  }
}
@media only screen and (max-height: 322px) {
  #contact {
    height: 250vh;
  }
}
@media only screen and (max-width: 960px) {
  #contact {
    height: 130vh;
  }
}
@media only screen and (max-width: 375px) {
  #contact {
    height: 125vh;
    padding: 1em;
  }
}
#landing {
  color: white;
  height: 100vh;
  background: linear-gradient(45deg, #202434 30%, #202440 40%, #202480 );
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#lights {
  width: 35vw;
  height: 110vh;
  position: absolute;
  z-index: 2;
  left: 0;
  opacity: 0.60;
  filter: brightness(3.3);
}

#lines {
  width: 75vw;
  height: 10vh;
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 12%;
}

/* Galaxy Fold */
@media only screen and (max-width: 281px) {
  #landing {
    height: 130vh;
  }
}
