#about {
  color: white;
  height: 100vh;
  background: linear-gradient(45deg, #202434 30%, #202440 70%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* @media only screen and (max-height: 960px) {
  #about {
    height: 130vh;
  }
}
@media only screen and (max-height: 394px) {
  #about {
    height: 200vh;
  }
}
@media only screen and (max-width: 960px) {
  #about {
    height: 130vh;
  }
}

@media only screen and (max-width: 375px) {
  #about {
    height: 140vh;
  }
} */
/* Galaxy Fold */
/* @media only screen and (max-width: 281px) {
  #about {
    height: 190vh;
  }
} */