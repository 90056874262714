#projects {
  color: white;
  max-height: 100vh;
  background: linear-gradient(45deg, #202434 30%, #202440 70%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

/* Nest Hub Max */
@media only screen and (max-height: 1281px) {
  #projects {
    height: 120vh;
  }
}
@media only screen and (max-height: 700px) {
  #projects {
    height: 150vh;
  }
}
@media only screen and (max-height: 322px) {
  #projects {
    height: 250vh;
  }
}
@media only screen and (max-width: 960px) {
  #projects {
    height: 130vh;
  }
}
@media only screen and (max-width: 958px) {
  #projects {
    height: 280vh;
  }
}

@media only screen and (max-width: 541px) {
  #projects {
    height: 310vh;
  }
}
/* iPhone XR */
@media only screen and (max-width: 415px) {
  #projects {
    height: 270vh;
  }
}
/* iPhone 12 Pro */
@media only screen and (max-width: 415px) {
  #projects {
    height: 290vh;
  }
}
/* iPhone SE */
@media only screen and (max-width: 375px) {
  #projects {
    height: 360vh;
  }
}
/* Galaxy S8+ */
@media only screen and (max-width: 361px) {
  #projects {
    height: 340vh;
  }
}
/* Galaxy Fold */
@media only screen and (max-width: 281px) {
  #projects {
    height: 400vh;
  }
}